import React from 'react';
import { Box, Text, Stack, HStack, VStack, Image } from '@chakra-ui/react';
import { Tag } from '@/components/ui/tag';
import { IoEyeSharp } from 'react-icons/io5';
import { FaEuroSign } from 'react-icons/fa';
import { FaImage, FaPlay } from 'react-icons/fa6';
import { Tooltip } from '@/components/ui/tooltip';
import { MediaType, SnapshotVideo } from '@/types';
import { Carousel } from './carousel';
import { TbCarouselHorizontalFilled } from 'react-icons/tb';
import { CountryIdentifier, MinifiedNumber } from '@/components';

export interface AdCardProps {
  title: string;
  totalReached?: number;
  formType?: string;
  endDate?: number;
  deliveryStartTime?: Date;
  language?: string;
  websiteUrl?: string | undefined;
  imageUrls?: string[] | undefined;
  videoUrl?: SnapshotVideo | undefined;
  isActive?: boolean;
  durationDays?: number;
  mediaType?: MediaType;
  onClick?: () => void;
}

export type TagData = {
  name: string;
  icon?: React.ReactElement<typeof Image>;
  content?: string | React.ReactElement;
  image?: { src: string; alt: string };
  tooltip?: string;
};

export const AdCard: React.FC<AdCardProps> = ({
  title,
  totalReached,
  formType,
  language,
  deliveryStartTime,
  websiteUrl,
  imageUrls: imagesUrl,
  videoUrl,
  isActive,
  durationDays,
  mediaType,
  onClick,
}: AdCardProps) => {
  const tagDataUpper: TagData[] = [
    {
      name: 'Europe',
      image: {
        src: '/european-union-svgrepo-com.svg',
        alt: 'europ-icon',
      },
      tooltip: "Diffusé dans l'Union Européenne",
    },
    {
      name: 'Langues',
      content: language ? (
        <CountryIdentifier countryCode={language} />
      ) : undefined,
      tooltip: 'Langue de la publicité',
    },
    {
      name: 'Duration',
      icon: (
        <Image
          src={
            isActive
              ? '/green_circle.gif'
              : 'https://upload.wikimedia.org/wikipedia/commons/0/0e/Basic_red_dot.png'
          }
          alt="active-icon"
          width={15}
          height={15}
        />
      ),
      content:
        durationDays !== undefined ? formatDurationShort(durationDays) : '',
      tooltip: deliveryStartTime
        ? `L'annonce a été diffusé pendant au moins ${durationDays} jours`
        : '',
    },
    {
      name: 'Form Type',
      image: formType
        ? {
            src:
              formType === 'meta'
                ? '/facebook_icon.svg'
                : '/globe-with-meridians-svgrepo-com.svg',
            alt: 'form-icon',
          }
        : undefined,
      tooltip: `Formulaire ${formType === 'meta' ? 'meta' : 'externe'}`,
    },
  ];
  const tagDataLower: TagData[] = [
    {
      name: 'Impression',
      icon: <IoEyeSharp />,
      content: totalReached ? (
        <MinifiedNumber number={totalReached} />
      ) : undefined,
      tooltip: 'Vues',
    },
    {
      name: 'Cost',
      icon: <FaEuroSign />,
      content: totalReached ? (
        <MinifiedNumber number={(totalReached ?? 0) * 0.009 * 0.95} />
      ) : undefined,
      tooltip: 'Coût estimé',
    },
  ];

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
      onClick={onClick}
    >
      {/* Contener Image/Video */}
      <Box position="relative" h="200px" cursor="pointer" overflow="hidden">
        <Carousel
          imageUrls={imagesUrl}
          videoUrl={videoUrl}
          isPreview={true}
          className="h-full object-cover"
        />
        <div className="absolute right-3 top-3 text-2xl text-white opacity-75">
          {mediaType === 'video' && <FaPlay />}
          {mediaType === 'carousel' && <TbCarouselHorizontalFilled />}
          {mediaType === 'image' && <FaImage />}
        </div>
        {/* Tags absolute */}
        <VStack
          position="absolute"
          bottom="10px"
          left="0"
          right="0"
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          px={4}
          gap={1}
        >
          {[tagDataUpper, tagDataLower].map((tagArray, index) => {
            return (
              <HStack
                key={index}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gap={1}
              >
                {displayTagData(
                  tagArray.filter((tag) => tag.content || tag.image),
                )}
              </HStack>
            );
          })}
        </VStack>
      </Box>

      {/* Footer text */}
      <Box p="4">
        <Stack gap="1">
          <Text fontWeight="bold" fontSize="lg">
            {title}
          </Text>
          <Text fontSize="sm" color="gray.500">
            {websiteUrl}
          </Text>
          {deliveryStartTime && (
            <Text fontSize="xs" color="gray.400">
              Publié le :{' '}
              {deliveryStartTime.toLocaleDateString('fr-fr', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </Text>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

/* Display tag info with an optional tooltip */
const displayTagData = (tagData: TagData[]) => {
  return tagData.map((tag, index) => {
    const tagJsx = (
      <Tag key={index} size="lg" startElement={tag.icon}>
        {tag.content && <Text fontWeight="bold">{tag.content}</Text>}
        {tag.image && (
          <Image
            src={tag.image.src}
            alt={tag.image.alt}
            width={15}
            height={15}
          />
        )}
      </Tag>
    );
    if (tag.tooltip) {
      return (
        <Tooltip key={index} content={tag.tooltip}>
          {tagJsx}
        </Tooltip>
      );
    }
    return tagJsx;
  });
};

const formatDurationShort = (durationInDays: number): string => {
  if (durationInDays > 365) return `${(durationInDays / 365).toFixed(0)}a`;
  if (durationInDays > 30) return `${(durationInDays / 30).toFixed(0)}m`;
  if (durationInDays >= 1) return `${durationInDays} j`;
  return '<1j';
};
