export const ROUTES = {
  AUTH: {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    SIGNUP: '/auth/login?screen_hint=signup',
  },
  UNAUTHORIZED: '/unauthorized',
  PLANS: '/plans',
  ROOT: '/',
  DASHBOARD: '/dashboard',
  ABOUT: {
    CGU: '/about/cgu',
    POLICY: '/about/policy',
    LEGAL: '/about/legal',
  },
};
