import {
  HStack,
  VStack,
  Heading,
  Text,
  Spacer,
  Separator,
} from '@chakra-ui/react';
import { AdDisplay } from './ad-display';
import { TableInfo } from '@/components';
import { MetaAd, MetaSnapshot } from '@/types';

interface AdDetailProps {
  ad: MetaAd;
  snapshot?: MetaSnapshot;
}

export const AdDetail: React.FC<AdDetailProps> = ({ ad, snapshot }) => {
  return (
    <>
      <HStack>
        <VStack gap={-1} alignItems={'flex-start'}>
          <Heading>{ad.page_name}</Heading>
          {ad.ad_delivery_start_time && (
            <Text fontSize="xs" color="gray.400" overflow={'hidden'}>
              Publié le :{' '}
              {ad.ad_delivery_start_time.toLocaleDateString('fr-fr', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </Text>
          )}
        </VStack>
        <Spacer />
      </HStack>
      <AdDisplay
        websiteUrl={ad.ad_creative_link_captions?.[0]}
        adUrl={ad.ad_snapshot_url}
        imageUrls={snapshot?.data?.images}
        videoUrl={snapshot?.data?.video}
        description={ad.ad_creative_bodies?.[0]}
      />
      <Separator />
      <HStack>
        <Text fontSize="xl">Information</Text>
        {ad.ad_delivery_start_time && (
          <Text fontSize="xs" color="gray.400">
            Publié le :{' '}
            {ad.ad_delivery_start_time.toLocaleDateString('fr-fr', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            })}
          </Text>
        )}
      </HStack>
      <TableInfo
        publisherPlatforms={ad.publisher_platforms}
        targetAges={ad.target_ages}
        targetGender={ad.target_gender}
        languages={ad.languages}
        deliveryStartTime={ad.ad_delivery_start_time}
        formType={ad.form_type}
        websiteUrl={ad.ad_creative_link_captions?.[0]}
        isActive={ad.isActive}
        durationDays={ad.activeDays}
      />
    </>
  );
};
