import React, { StrictMode } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { ROUTES } from '@/types';
import {
  ProtectedDashboard,
  Plans,
  Cgu,
  Legal,
  Policy,
  Unauthorized,
} from '@/pages';
import { LoginProvider, StorageProvider, UiProvider } from '@/providers';
import './index.css';
import { Layout } from './layout';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://8c07081c4ea6d5b430adb7ef7493a5c8@o4508456176189440.ingest.de.sentry.io/4508456575041616',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.2, //  Capture 20% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://leadspy.app/',
    import.meta.env.VITE_API_BASE_URL,
    import.meta.env.VITE_SCRAPPER_BASE_URL,
    import.meta.env.VITE_ADS_API_BASE_URL,
  ],
  // Envrionnement
  environment: process.env.NODE_ENV || 'development',
  // Session Replay
  replaysSessionSampleRate: 0.0, // This sets the sample rate at 10% (0% now). You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <UiProvider>
      <LoginProvider>
        <StorageProvider>
          <BrowserRouter>
            <Layout>
              <Routes>
                <Route
                  path={ROUTES.DASHBOARD}
                  element={<ProtectedDashboard />}
                />
                <Route
                  path={ROUTES.ROOT}
                  element={<Navigate to={ROUTES.DASHBOARD} />}
                />
                <Route path={ROUTES.PLANS} element={<Plans />} />
                <Route path={ROUTES.ABOUT.CGU} element={<Cgu />} />
                <Route path={ROUTES.ABOUT.LEGAL} element={<Legal />} />
                <Route path={ROUTES.ABOUT.POLICY} element={<Policy />} />
                <Route path={ROUTES.UNAUTHORIZED} element={<Unauthorized />} />
              </Routes>
            </Layout>
            <ToastContainer />
          </BrowserRouter>
        </StorageProvider>
      </LoginProvider>
    </UiProvider>
  </StrictMode>,
);
