import at from '../assets/flags/at.svg';
import be from '../assets/flags/be.svg';
import bg from '../assets/flags/bg.svg';
import ch from '../assets/flags/ch.svg';
import cz from '../assets/flags/cz.svg';
import de from '../assets/flags/de.svg';
import dk from '../assets/flags/dk.svg';
import es from '../assets/flags/es.svg';
import fi from '../assets/flags/fi.svg';
import fr from '../assets/flags/fr.svg';
import gb from '../assets/flags/gb.svg';
import gr from '../assets/flags/gr.svg';
import ie from '../assets/flags/ie.svg';
import it from '../assets/flags/it.svg';
import lu from '../assets/flags/lu.svg';
import mt from '../assets/flags/mt.svg';
import nl from '../assets/flags/nl.svg';
import no from '../assets/flags/no.svg';
import pl from '../assets/flags/pl.svg';
import pt from '../assets/flags/pt.svg';
import se from '../assets/flags/se.svg';

interface CountryIdentifierProps {
  countryCode: string; // according to COUNTRY CODE ISO 3166 rule
}

export const CountryIdentifier: React.FC<CountryIdentifierProps> = ({
  countryCode,
}) => {
  const formattedCountryCode = countryCode.toUpperCase();
  // svgs imported from https://nucleoapp.com/svg-flag-icons
  const SUPPORTED_FLAGS = {
    AT: at,
    BE: be,
    BG: bg,
    CH: ch,
    CZ: cz,
    DE: de,
    DK: dk,
    ES: es,
    FI: fi,
    FR: fr,
    GB: gb,
    GR: gr,
    IE: ie,
    IT: it,
    LU: lu,
    MT: mt,
    NL: nl,
    NO: no,
    PL: pl,
    PT: pt,
    SE: se,
  };
  if (formattedCountryCode in SUPPORTED_FLAGS) {
    return (
      <img
        src={
          SUPPORTED_FLAGS[formattedCountryCode as keyof typeof SUPPORTED_FLAGS]
        }
        width={24}
        height={24}
      />
    );
  }
  return <>{formattedCountryCode}</>;
};
